<template>
<div class="sidebar-left">
    <div class="sidebar">
        <div class="sidebar-content todo-sidebar">
            <div class="todo-app-menu">
                <div class="add-task">
                    <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" block @click="$emit('update:is-task-handler-sidebar-active', true); $emit('close-left-sidebar')">
                        Add Contact
                    </b-button>
                  
                </div>
                  <b-form-group label-for="serachContactGroup" style="padding: 0 5px 0 5px">
                        <b-form-input id="serachContactGroup" placeholder="Search Contact" v-model="group_name" v-on:keyup="getAllcontactGroups()" />
                    </b-form-group>
                <vue-perfect-scrollbar :settings="perfectScrollbarSettings" class="sidebar-menu-list scroll-area" style="padding: 0 5px 0 5px">
                    <!-- Filters -->
                    <b-list-group class="list-group-filters">
                        <b-nav vertical class="wrap-border">
                            <b-list-group-item class="wrap-border" v-for="filter in taskFilters" :key="filter.title + $route.path" :to="filter.route" :active="isDynamicRouteActive(filter.route)" @click="$emit('close-left-sidebar')">
                                <span class="line-height-1">{{ filter.title }}</span>
                               <!--  <span class="line-height-2"><br /><small>
                                        <feather-icon :icon="filter.icon" size="18" class="mr-75" /> By {{filter.created_by}}</small></span>
                                <span class="align-text-bottom"><br /><small>Created: {{filter.date | formatDate}}</small></span> -->
                                <b-dropdown-divider />
                            </b-list-group-item>

                        </b-nav>

                    </b-list-group>
                     <!-- <div class="no-results" :class="{'show': !taskFilters.length}">
                        <h5>No Contact Found</h5>
                    </div> -->
                    <!-- Tags -->

                </vue-perfect-scrollbar>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import {
    BNav,
    BNavItem,
    BButton,
    BListGroup,
    BAlert,
    BListGroupItem,
    BDropdownDivider,
     BFormInput,
    BInputGroup,
    BFormGroup,
} from 'bootstrap-vue'
import {
    isDynamicRouteActive
} from '@core/utils/utils'
import Ripple from 'vue-ripple-directive'
import $ from 'jquery'
import store from '@/store'
import moment from "moment"
import {
    heightFade
} from '@core/directives/animations'
export default {
    directives: {
        Ripple,
        heightFade
    },
    components: {
        BNav,
        BNavItem,
        BButton,
        BListGroup,
        BAlert,
        BListGroupItem,
        BDropdownDivider,
        VuePerfectScrollbar,
         BFormInput,
    BInputGroup,
    BFormGroup,
    },
    props: {
        taskTags: {
            type: Array,
            required: true,
        },
    },
    setup() {
        const perfectScrollbarSettings = {
            maxScrollbarLength: 60,
        }

        return {
            perfectScrollbarSettings,
            isDynamicRouteActive,
        }
    },
    data() {
        return {
            contaactGroup: [],
            taskFilters: [],
            group_name:""
        }
    },
    mounted() {
        this.getAllcontactGroups();
    },
    methods: {
        getAllcontactGroups() {
            let vm = this
            vm.taskFilters =[]
            $.get({
                url: store.state.rootUrl + 'contact/v1/view/groups',
                type: "get",
                async: true,
                crossDomain: true,
                headers: {
                    "Content-Type": "application/json",
                    "X-Requested-With": "XMLHttpRequest",
                    "X-Authorization-Key": vm.$cookies.get("token"),
                },
                data: {
                    listName:  vm.group_name,
                    limit: 100
                },
                success: function (response, status, jQxhr) {

                    $('#resetPWD').html(' Reset Password');
                    if (response.data.code != 200) {

                    } else {
                        let result = response.data.data;
                        let listID=""
                        for (var i = 0; i < result.length; i++) {
                            if (result[i].status == 1) {
                                if(listID ==""){
                                    listID = result[i].list_id
                                }
                                var contact = {
                                    title: result[i].group_name,
                                    date: result[i].created_at,
                                    created_by: result[i].created_by,
                                    icon: 'UsersIcon',
                                    route: {
                                        name: 'apps-contact-filter',
                                        params: {
                                            filter: result[i].list_id
                                        }
                                    }
                                }
                                vm.taskFilters.push(contact)
                            }

                        }
                        vm.$router.push({
                            name: 'apps-contact-filter',
                            params: {
                                filter: listID
                            }
                        })

                    }
                },
                error: function (jQxhr, status, error) {
                    if (jQxhr.responseJSON.data.code == 401 || jQxhr.responseJSON.data.code == 500 || jQxhr.responseJSON.data.code == 403 || jQxhr.responseJSON.data.code == 404) {
                        vm.$cookies.remove("token");
                        vm.$cookies.remove("clientData");
                        // Redirect to login page
                        vm.$router.push({ name: 'login' })
                        vm.$toast({
                            component: ToastificationContent,
                            props: {
                                title: jQxhr.responseJSON.data.message,
                                icon: 'EditIcon',
                                variant: 'failed',
                            },
                        })
                    }
                }
            });
        }
    },
    filters: {
        formatDate: function (value) {
            if (!value) return "";
            return moment(value).fromNow();
        },
    }
}
</script>

<style>
.list-group .list-group-item.active small {
    color: #FF2447 !important;
}

[dir] .todo-application .content-area-wrapper .sidebar .todo-sidebar .todo-app-menu .list-group .list-group-item.active {
    border-color: #FF2447;
}
</style>
