<template>
<div>
    <b-sidebar id="sidebar-task-handler" sidebar-class="sidebar-lg" :visible="isTaskHandlerSidebarActive" bg-variant="white" shadow backdrop no-header right @change="(val) => $emit('update:is-task-handler-sidebar-active', val)" @hidden="clearForm">
        <b-alert v-height-fade.appear :show="dismissCountDown" dismissible fade variant="danger" class="mb-0" @dismiss-count-down="countDownChanged">
                <div class="alert-body">
                    <span>{{errorMessage}}</span>
                </div>
            </b-alert>
        <template #default="{ hide }">
            <!-- Header -->
            <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
                <b-button v-if="taskLocal.id" size="sm" :variant="taskLocal.isCompleted ? 'outline-success' : 'outline-secondary'" @click="taskLocal.isCompleted = !taskLocal.isCompleted">
                    {{ taskLocal.isCompleted ? 'Completed' : 'Mark Complete' }}
                </b-button>
                <h5 v-else class="mb-0">
                    Add Contact
                </h5>
                <div>
                    <feather-icon v-show="taskLocal.id" icon="TrashIcon" class="cursor-pointer" @click="$emit('remove-task'); hide();" />
                    <feather-icon class="ml-1 cursor-pointer" icon="StarIcon" size="16" :class="{ 'text-warning': taskLocal.isImportant }" @click="taskLocal.isImportant = !taskLocal.isImportant" />
                    <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
                </div>
            </div>

            <!-- Body -->
            <validation-observer #default="{ handleSubmit }" ref="refFormObserver">

                <!-- Form -->
                <b-form class="p-2" @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">

                    <!-- Title -->
                    <validation-provider #default="validationContext" name="Title" rules="required">
                        <b-form-group label="Title" label-for="task-title">
                            <b-form-input id="task-title" v-model="title" autofocus :state="getValidationState(validationContext)" trim placeholder="Contact  Name" required />

                            <b-form-invalid-feedback>
                                {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <!-- Description -->
                    <b-form-group label="Description" label-for="task-description">
                        <quill-editor id="quil-content" v-model="description" :options="editorOption" class="border-bottom-0" required />
                        <div id="quill-toolbar" class="d-flex justify-content-end border-top-0">
                            <!-- Add a bold button -->
                            <button class="ql-bold" />
                            <button class="ql-italic" />
                            <button class="ql-underline" />
                            <button class="ql-align" />
                            <button class="ql-link" />
                        </div>
                    </b-form-group>
                    <b-form-group label="Custom Fields" label-for="custom-fields">
                    <b-form-tags v-model="customFields" no-outer-focus >
                        <template #default="{ tags, inputAttrs, inputHandlers, addTag, removeTag }">
                            <b-input-group aria-controls="my-custom-tags-list" label="Custom Fields">
                                <input v-bind="inputAttrs" placeholder="Custom field - Press enter to add" class="form-control" v-on="inputHandlers">
                                <b-input-group-append>
                                    <b-button variant="primary" @click="addTag()">
                                        Add
                                    </b-button>
                                </b-input-group-append>
                            </b-input-group>
                            <ul id="my-custom-tags-list" class="list-unstyled d-inline-flex flex-wrap mb-0" aria-live="polite" aria-atomic="false" aria-relevant="additions removals">
                                <!-- Always use the tag value as the :key, not the index! -->
                                <!-- Otherwise screen readers will not read the tag
                                additions and removals correctly -->
                                <b-card v-for="tag in tags" :id="`my-custom-tags-tag_${tag.replace(/\s/g, '_')}_`" :key="tag" tag="li" class="shadow-none border mt-1 mr-1 mb-0" body-class="py-1 pr-2 text-nowrap">
                                    <strong>{{ tag }}</strong>
                                    <b-button variant="link" size="sm" :aria-controls="`my-custom-tags-tag_${tag.replace(/\s/g, '_')}_`" class="py-0" @click="removeTag(tag)">
                                        remove
                                    </b-button>
                                </b-card>
                            </ul>
                        </template>
                    </b-form-tags>
                    </b-form-group>
                    <!-- Form Actions -->
                    <div class="d-flex mt-2">
                        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" @click.prevent="contactCreationForm" id="contactCreate" variant="primary" class="mr-2" type="submit">
                            Create
                        </b-button>
                        <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="reset" variant="outline-secondary">
                            Reset
                        </b-button>
                    </div>
                </b-form>
            </validation-observer>
        </template>
    </b-sidebar>
</div>
</template>

<script>
import {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BAvatar,
    BButton,
    BFormInvalidFeedback,
    BSpinner,
    BAlert,
    BFormTags,
    BInputGroup,
    BInputGroupAppend,
    BCard,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'
import {
    ValidationProvider,
    ValidationObserver
} from 'vee-validate'
import {
    required,
    email,
    url
} from '@validations'
import {
    avatarText
} from '@core/utils/filter'
import formValidation from '@core/comp-functions/forms/form-validation'
import {
    toRefs
} from '@vue/composition-api'
import {
    quillEditor
} from 'vue-quill-editor'
import useTaskHandler from './useTaskHandler'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import $ from 'jquery'
import store from '@/store'

import {
    heightFade
} from '@core/directives/animations'
export default {
    components: {
        // BSV
        BButton,
        BSidebar,
        BForm,
        BFormGroup,
        BFormInput,
        BAvatar,
        BFormInvalidFeedback,

        // 3rd party packages
        vSelect,
        flatPickr,
        quillEditor,
        BSpinner,
        BAlert,
        BFormTags,
        BInputGroup,
        BInputGroupAppend,
        BCard,
        // Form Validation
        ValidationProvider,
        ValidationObserver,
    },
    directives: {
        heightFade,
        Ripple,
    },
    model: {
        prop: 'isTaskHandlerSidebarActive',
        event: 'update:is-task-handler-sidebar-active',
    },
    props: {
        isTaskHandlerSidebarActive: {
            type: Boolean,
            required: true,
        },
        task: {
            type: Object,
            required: true,
        },
        clearTaskData: {
            type: Function,
            required: true,
        },
    },
    data() {
        return {
            required,
            email,
            url,
            title: "",
            description: "",
            errorMessage: "",
            countryCodeOptions: [],
            dismissSecs: 5,
            dismissCountDown: 0,
            showDismissibleAlert: false,
            isLoading: false,
            customFields: [],
            custom1:"",
            custom2:"",
            custom3:"",
            custom4:"",
            custom5:"",
        }
    },
    setup(props, {
        emit
    }) {
        const {
            taskLocal,
            resetTaskLocal,

            // UI
            assigneeOptions,
            onSubmit,
            tagOptions,
            resolveAvatarVariant,
        } = useTaskHandler(toRefs(props), emit)

        const {
            refFormObserver,
            getValidationState,
            resetForm,
            clearForm,
        } = formValidation(resetTaskLocal, props.clearTaskData)

        const editorOption = {
            modules: {
                toolbar: '#quill-toolbar',
            },
            placeholder: 'Write your description',
        }

        return {
            // Add New
            taskLocal,
            onSubmit,
            assigneeOptions,
            tagOptions,

            // Form Validation
            resetForm,
            clearForm,
            refFormObserver,
            getValidationState,

            // UI
            editorOption,
            resolveAvatarVariant,

            // Filter/Formatter
            avatarText,
        }
    },
    methods: {
        countDownChanged(dismissCountDown) {
            this.dismissCountDown = dismissCountDown
        },
        contactCreationForm() {
            let vm = this
            vm.isLoading = true
            for (let i =0; i<vm.customFields.length; i++){
                if(i==0){
                  vm.custom1 = vm.customFields[i]
                }
                if(i==1){
                  vm.custom2 = vm.customFields[i]
                }
                if(i==2){
                  vm.custom3 = vm.customFields[i]
                }
                if(i==3){
                  vm.custom4 = vm.customFields[i]
                }
                if(i==4){
                  vm.custom5 = vm.customFields[i]
                }
            }
            $('#contactCreate').html(' Please Wait ...');
            $.post({
                url: store.state.rootUrl + 'contact/v1/list_create',
                post: "POST",
                headers: {
                    "X-Authorization-Key": vm.$cookies.get("token"),
                    "X-Requested-With": "XMLHttpRequest",
                    "Content-Type": "application/json",
                },
                data: JSON.stringify({
                    listName: vm.title,
                    listDesc: vm.description,
                    listCustom1:  vm.custom1,
                    listCustom2: vm.custom2,
                    listCustom3: vm.custom3,
                    listCustom4: vm.custom4,
                    listCustom5: vm.custom5
                }),
                success: function (response, status, jQxhr) {
                    vm.isLoading = false
                    $('#contactCreate').html(' Create');
                    if (response.data.code != 200) {
                        console.log("error message " + response.data.message)
                        vm.dismissCountDown = vm.dismissSecs
                        vm.showDismissibleAlert = true
                        vm.errorMessage = response.data.message

                    } else {
                        
                        vm.$toast({
                            component: ToastificationContent,
                            props: {
                                title: response.data.message,
                                icon: 'EditIcon',
                                variant: 'success',
                            },
                        })
                        vm.isTaskHandlerSidebarActive = false

                    }

                },
                error: function (jQxhr, status, error) {
                    vm.isLoading = false
                    vm.dismissCountDown = vm.dismissSecs
                    vm.showDismissibleAlert = true;
                    $('#contactCreate').html(' Create');
                    var statustext = jQxhr.responseJSON.statusDescription;
                    console.log("json String Test "+statustext)
                    
                    if (!statustext) {
                        vm.errorMessage = "An error occurred. Try again later.";
                    } else {
                        vm.errorMessage = statustext;
                    }
                }
            });

        },
    }

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/quill.scss';
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';

.assignee-selector {
    ::v-deep .vs__dropdown-toggle {
        padding-left: 0;
    }
}

#quil-content ::v-deep {
    >.ql-container {
        border-bottom: 0;
    }

    +#quill-toolbar {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-bottom-left-radius: $border-radius;
        border-bottom-right-radius: $border-radius;
    }
}
</style>
